import React from "react";
import Select from "react-select";

function ProductDescription({ data, option, setOption }) {
  let options = null;
  options = data.length !== 0 && options === null ? data[0].img : null;

  const handleChange = (selectedOption) => {
    setOption(selectedOption.quantity);
  };

  return (
    <div className="productdesc">
      <div className="row">
        <div className="col-lg-5 d-none d-lg-block">
          {data.length !== 0
            ? data[0].img.map((product, index) =>
                product.quantity === option ? (
                  <div
                    key={`${index} img`}
                    style={{
                      backgroundImage: `url(../images/${product.src})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      maxWidth: "100%",
                      minHeight: "100%",
                    }}
                  ></div>
                ) : null
              )
            : null}
        </div>
        <div className="col-md-12 col-lg-7 d-flex align-items-center">
          <div className="row productdescr text-justify">
            <div className="row">
              <h3 style={{ fontSize: "2.8rem" }}>
                {data.length !== 0 ? data[0].title : null}
              </h3>
            </div>
            <div className="row pt-3 mb-2">
              <h5
                className="col-md-3 col-lg-3 my-auto"
                style={{ fontSize: "1rem" }}
              >
                <span>Quantities: </span>
              </h5>
              <div className="col-md-5 col-lg-4" style={{ fontSize: "1rem" }}>
                <Select
                  value={option}
                  onChange={handleChange}
                  options={options}
                  placeholder={`${option} grams`}
                  isSearchable={false}
                />
              </div>
            </div>
            {/* <div className="row">
                            <h5 style={{ fontSize: "1rem" }}><span >Price:</span>
                                {price.map((product, index) => (
                                    product.quantity === option.selectedOption ? product.cost : null
                                ))}
                            </h5>
                        </div> */}
            <div className="row  my-2 d-lg-none">
              <div className="col-md-12">
                {data.length !== 0
                  ? data[0].img.map((product, index) =>
                      product.quantity === option ? (
                        <div
                          key={`${index} small picture`}
                          style={{
                            backgroundImage: `url(images/${product.src})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            maxWidth: "100%",
                            minHeight: "250px",
                          }}
                        ></div>
                      ) : null
                    )
                  : null}
              </div>
            </div>
            <div className="row">
              <h5 style={{ fontSize: "1rem" }}>
                <span>Ingredients: </span>
                {data.length !== 0 ? data[0].ingredients : null}
              </h5>
            </div>
            <div className="row">
              <h5 style={{ fontSize: "1rem" }}>
                <span>Allergen Advice: </span>
                {data.length !== 0 ? data[0].advice : null}
              </h5>
            </div>
            <div className="row ">
              <h5 className="m-0" style={{ fontSize: "1rem" }}>
                <span>Benefits: </span>
                <ul className="pt-2">
                  {data.length !== 0
                    ? data[0].benefits.map((product, index) => (
                        <li
                          style={{ fontSize: "1rem", lineHeight: "1.1rem" }}
                          key={`${index} point`}
                          className="py-1 m-0"
                        >
                          {product}
                        </li>
                      ))
                    : null}
                </ul>
              </h5>
            </div>
            <div className="row">
              <h5 style={{ fontSize: "1rem" }}>
                <span>Storage Details: </span>
                {data.length !== 0 ? data[0].storage : null}
              </h5>
            </div>
            <div className="row py-1">
              {data.length !== 0
                ? data[0].icons.map((product, index) => (
                    <div key={`${index} icon`} className="col ">
                      <div
                        className="col"
                        style={{
                          backgroundImage: `url("images/${product.img}")`,
                          backgroundSize: "contain",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          maxWidth: "100%",
                          height: "60px",
                        }}
                      ></div>
                      <h6 className="py-1 text-center">{product.label}</h6>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDescription;
