import React, { useEffect, useState } from 'react'
import logo from "../images/GK-bhats-logo.png"

function Navbar() {

    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);

     const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    const darkshade = {
        backgroundColor: "rgba(0, 0, 0, 0.85)",
        color: "white",
        transition: "all 1s",

    }
    const lightside = {
        backgroundColor: "rgba(0, 0, 0, 0.0)",
        color: "black",
        transition: "all 1s",

    }

    const handleScroll = () => {
        // find current scroll position
        const currentScrollPos = window.pageYOffset;
        // set state based on location info (explained in more detail below)
        setVisible((prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 150) || currentScrollPos < 100);
        // set state to new scroll position
        setPrevScrollPos(currentScrollPos);
    };

    useEffect(() => {
        window.addEventListener('scroll', () => handleScroll());
        return () => window.removeEventListener('scroll', () => handleScroll());
      
    }, [prevScrollPos, visible, handleScroll]);

    return (
        <div>
            <nav className="navbar navbar-expand-lg fixed-top text-white navbar-dark m-0  " style={visible ? { ...lightside } : { ...darkshade }} id="myScrollspy">
                <div className="container-fluid m-0">
                    <a className="navbar-brand  col-lg-1 text-center" href="#header">
                        <img src={logo} alt="" style={visible ? { ...lightside, backgroundColor: null, transform: "scale(1.5)" } : { ...darkshade, backgroundColor: null, transform: "scale(1.5)" }} width="50" height="51" />
                    </a>
                    <button className="navbar-toggler" type="button" aria-controls="navbarNavAltMarkup" aria-expanded={!isNavCollapsed ? true : false}  data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-label="Toggle navigation" onClick={handleNavCollapse}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`col-lg-4 offset-lg-7 d-lg-flex justify-content-lg-evenly ${isNavCollapsed ? 'collapse ' : ''} navbar-collapse`}  id="navbarNavAltMarkup">
                        <div className="navbar-nav pt-2 ">
                        <a className="nav-link mx-4 d-lg-block d-none " href="#about">About</a>
                            <a className="nav-link mx-4 d-lg-block d-none" href="#products">Products</a>
                            <a className="nav-link mx-4 d-lg-block d-none " href="#contact">Contact</a>
                            <a className="nav-link mx-4 d-lg-none d-block " href="#about" onClick={()=>setIsNavCollapsed(true)}>About</a>
                            <a className="nav-link mx-4 d-lg-none d-block" href="#products" onClick={()=>setIsNavCollapsed(true)}>Products</a>
                            <a className="nav-link mx-4 d-lg-none d-block" href="#contact" onClick={()=>setIsNavCollapsed(true)}>Contact</a>
                        </div>
                    </div>
                </div>
            </nav>

        </div >
    )
}

export default Navbar
