import React from "react";

function About() {
  return (
    <div
      id="about"
      className="row justify-content-center py-5 border-0 bg-dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <div className="col-md-12" id="image2"></div>
      <div
        className="col-md-10 col-lg-7 my-auto "
        style={{ zIndex: 100, backgroundColor: "aliceblue" }}
      >
        <div className="row py-5 " style={{ minHeight: "65vh" }}>
          <div className="col-md-6 align-self-center ">
            <h3
              className="px-5 right-border"
              style={{ fontFamily: "'Bodoni Moda', serif", fontSize: "2.5rem" }}
            >
              GK Bhat's heirloom recipes are the perfect additon to your
              nutritional meals
            </h3>
          </div>
          <hr className="d-block d-md-none" />
          <div className="col-md-6 align-self-center px-5">
            <h6>
              Our products are made using traditional methods of preparations,
              with some of the finest spices and all natural ingredients. Our
              recipe, which has been passed down to us through generations,
              hasn’t changed over the years.
            </h6>
            <h6>Taste it once and you are sure to come back for more!</h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
