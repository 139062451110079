import React, { useState } from 'react'


function Header() {

    const [onHover, setOnHover] = useState(false)

    const light = {
        backgroundColor: 'white',
        color: '#212529',
        border: "1px solid black",
        fontSize: "0.7rem"
    }

    const dark = {
        backgroundColor: '#212529',
        color: 'white',
        border: "1px solid white",
        fontSize: "0.7rem"

    }

    return (
        <div>

            <div className=" text-white bg-dark content-container " id="header">
                <div className="row gx-0" style={{ minHeight: "100vh" }}>
                    <div className="col-md-8 d-none d-md-block ">
                        <div id="image1"></div>
                    </div>
                    <div className="col-md-8 d-block d-md-none " style={{ minHeight: "100vh" }}>
                        <div id="image1"></div>
                    </div>
                    <div className=" col-md-4 mx-auto my-auto">
                        <div className="row mx-5 gx-0 " >
                            <div className="row text-left">
                                <span className="mt-3 p-0" style={{ fontFamily: "'Bodoni Moda', serif", fontSize: "2.5rem"  }} >GK Bhat's Pickle</span>
                                <hr />
                            </div>
                            <div className="row text-left "><span className="p-0" style={{ fontSize: "1rem" }}>Signature blend of spices passed on from generations</span></div>
                            <div className="row mt-3 "   >
                                <a className="btn d-grid gap-2 col-md-4  w-60 " style={onHover ? light : dark} onMouseOver={() => setOnHover(true)} onMouseOut={() => setOnHover(false)} href="#products" role="button" >View Products</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Header
