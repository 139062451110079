import React, {useState, useEffect } from 'react'
import ReactStars from "react-rating-stars-component";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function Testimonials() {
    const stars = {
        size: 30,
        edit: false,
    };
    const [Testimonials,setTestimonials] =useState(null);
    const [Google,setGoogle] =useState(null);


    useEffect(() => {
        fetch('./JSON/Testimonals.json').then(response => {
            response.json().then(settings => {
                // instead of setting state you can use it any other way
                setTestimonials(settings);
            })
        })

        fetch('./JSON/config.json').then(response => {
            response.json().then(settings => {
                // instead of setting state you can use it any other way
                setGoogle(settings["googleReview"]);
            })
        })
    }, [])

    return ( 
        <div style={{minHeight: "50vh"}} className="row d-flex  py-3 align-items-center bg-dark">
            <div className="bg-dark mx-auto d-flex justify-content-center ">
                <div className="bg-dark text-white carouselExample d-xs-none d-md-flex align-items-center" >
                    <div >
                        <Carousel
                            autoPlay={true}
                            infiniteLoop={true}
                            interval={5000}
                            emulateTouch={true}
                            showStatus={false}
                            showIndicators={false}
                            showThumbs={false}
                            >
                {Testimonials !== null ? Testimonials.map((product, index) => (
                        <div key={`${index} testimonial`} className="row d-flex justify-content-center">
                            <div className="col-md-3 my-auto" >
                                <div style={{backgroundImage: `url("images/${product.image}")`,backgroundSize: "cover",backgroundPosition: "center",backgroundRepeat: "no-repeat" ,width: "100px",borderRadius: "50%",border: "1px solid black",height: "100px"}} className="mx-auto">
                                </div>
                                <h6 className="my-auto pt-2" style={{ textAlign: "center" }}>{product.name}</h6>
                            </div>
                            <div className="col-md-5">
                                <div className="row">
                                    <ReactStars {...stars} value={product.stars} />
                                </div>
                                <div className="row">
                                    <h6 style={{textAlign:"justify"}}>
                                   {product.testimonial}
                                    </h6>
                                </div>
                            </div>
                        </div>
                        )):null}
                    </Carousel>
                </div>
            </div >
       </div>
       {Google != null && Google.length < 1  ? null: 
       <div className="bg-dark mx-auto row d-flex justify-content-center"> 
            <div className="col-md-4 bg-light ">
                <div className="row d-flex justify-content-center">
                <h6 className="py-3 px-1 text-center col-md-7 my-auto">Your feedback is valuable to us</h6> 
                <a className="p-0 d-flex justify-content-center col-6 my-2 mx-3 text-decoration-none btn-sm col-md-3 " target="_blank" rel="noreferrer" href={Google} >
                <button type="button" className="btn w-100 btn-dark text-white  ">Review</button></a>
                </div>
            </div>
        </div> 
        }
       </div >


    )
}

export default Testimonials
