import React, { useState,useEffect } from 'react'
import Model from './Model';

function Products() {

    const [productDescription,setproductDescription] = useState(null);

    useEffect(() => {
        fetch('./JSON/Products.json').then(response => {
            response.json().then(settings => {
                // instead of setting state you can use it any other way
                setproductDescription(settings);
            })
        })
    }, [])

    const [data, setData] = useState([]);
    const [option, setOption] = useState(300)




    const handledata = (curModal) => {
        setOption(300)
        const tempdata = productDescription.filter((item) => item.id === curModal)
        setData(tempdata)
    }



    return (
        <div className="container d-flex mb-4 align-items-start align-items-center " style={{ minHeight: "100vh" }} id="products">
            <div className="col-md-12 mx-auto">
                <h3 className="row mb-1 mt-4   justify-content-center" style={{ fontFamily: "'Bodoni Moda', serif" }}> Our Products </h3>
                <div className="row  " >
                    {productDescription !== null ? productDescription.map((product, index) => (
                        <div key={`${index} product`} className="col-md-3 ">
                            <div className="row d-flex justify-content-center growhover">
                                <div  style={{backgroundImage: `url('images/${product.mainimg}')`,backgroundSize: 'contain',backgroundPosition:'center',backgroundRepeat: 'no-repeat',width: "350px",height: "350px"}}></div>
                            </div>
                            <div className="row my-4">
                                <h5 className="text-center" style={{ fontFamily: "'Bodoni Moda', serif" }}> {product.title} </h5>
                            </div>
                            <div className="row">
                                <button type="button" onClick={() => handledata(product.id)} style={{ textDecoration: "none", fontFamily: "'Bodoni Moda', serif" }} className="btn btn-outline-dark d-grid gap-2 col-6 mx-auto" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    View Details
                                </button>
                            </div>
                        </div>
                    )):null}
                </div>
            </div>
            <Model data={data} option={option} setOption={setOption} />
        </div>
    )
}

export default Products
