// import logo from './logo.svg';
import React, { Fragment } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import About from './components/About';
import Form from "./components/Form";
import Header from './components/Header';
import Navbar from './components/Navbar';
import NotFound from './components/NotFound';
import Products from './components/Products';
// import ProductDescription from './components/ProductDescription';
import Testimonials from './components/Testimonials';

import './App.css';


function App() {
  return (
    <div className="App " style={{ overflow: "hidden" }} >
      <BrowserRouter>
        <Switch>
          {/* <Route path="/product/:id" component={ProductDetails} /> */}
          <Route path="/not-found" component={NotFoundPage} />
          <Route path="/" exact component={Home} />
          <Redirect to="/not-found" />
        </Switch>
      </BrowserRouter>
    </div>
  );
}
const Home = () => (
  <Fragment>
    <Navbar />
    <Header />
    <About />
    <Products />
    <Testimonials />
    <Form />
  </Fragment >
);

// const ProductDetails = (props) => (
//   <Fragment>
//     <ProductDescription {...props} />
//   </Fragment>
// );

const NotFoundPage = () => (
  <Fragment>
    <NotFound />
  </Fragment>
);



export default App;
