import React, { useState, useEffect } from "react";
const axios = require("axios");

function Form() {
  const [onHover, setOnHover] = useState({
    state: false,
    type: 1,
  });
  const [Endpoint, setEndpoint] = useState({
    form: "",
    facebook: "",
    instagram: "",
  });

  const [Name, setName] = useState("");
  const [Phone, setPhone] = useState("");
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");

  const [submit, setSubmit] = useState(false);

  const icons = {
    bottom: "50%",
    right: "0",
    textAlign: "left",
    position: "absolute",
    transform: "translate(0, 50%)",
  };
  useEffect(() => {
    fetch("./JSON/config.json").then((response) => {
      response.json().then((settings) => {
        // instead of setting state you can use it any other way
        setEndpoint({
          form: settings["formEndpoint"],
          facebook: settings["facebook"],
          instagram: settings["instagram"],
        });
      });
    });
  }, []);

  let handelForm = async (e) => {
    e.preventDefault();

    if (
      Name.length < 1 ||
      Phone.length < 1 ||
      Email.length < 1 ||
      Message.length < 1
    ) {
      window.alert("Enter valid information.");
      return false;
    }

    let phonereg = /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/;
    let OK = phonereg.exec(Phone);
    if (!OK) {
      window.alert("Enter a vaild phone number.");
      return false;
    }

    let emailreg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let IsOk = emailreg.exec(Email);
    if (!IsOk) {
      window.alert("Enter a vaild email address.");
      return false;
    }

    await axios
      .post(Endpoint.form, {
        name: Name,
        phoneNumber: Phone,
        email: Email,
        message: Message,
      })
      .then(function (response) {
        // console.log(response);
        setSubmit(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const change = {
    transform: "translateX(20%)",
    transition: "1.0s",
    fontSize: "25px",
    color: "black",
    backgroundColor: "white",
    minHeight: "26px",
    width: "100%",
  };

  const nochange = {
    transform: "translateX(85%)",
    transition: "1.0s",
    fontSize: "25px",
    color: "white",
    backgroundColor: "black",
    minHeight: "26px",
    width: "100%",
  };

  return (
    <div>
      <div
        className="row d-flex align-items-center m-0 p-0  "
        style={{ minHeight: "50vh", position: "relative" }}
        id="contact"
      >
        <form onSubmit={handelForm} className="row pb-1 g-3 mx-auto">
          <div className="col-md-6 d-flex justify-content-center">
            <div className="row d-flex justify-content-center ">
              {submit === true ? (
                <h4 className="col-6 d-flex justify-content-center align-items-center text-center">
                  Thank you for your query. We will get back to you shortly!{" "}
                </h4>
              ) : (
                <React.Fragment>
                  <h4
                    className="touch"
                    style={{ fontFamily: "'Bodoni Moda', serif" }}
                  >
                    Get in Touch
                  </h4>
                  <div className="row mt-3 d-flex justify-content-center">
                    <div className="col-md-10">
                      <input
                        type="text"
                        className="form-control"
                        id="inputName"
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Name"
                      />
                    </div>
                  </div>
                  <div className="row mt-3 d-flex justify-content-center">
                    <div className="col-md-5">
                      <input
                        type="text"
                        className="form-control"
                        id="inputPhone"
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Phone"
                      />
                    </div>
                    <div className="col-md-5">
                      <input
                        type="email"
                        className="form-control"
                        id="inputEmail4"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="row mt-3 d-flex justify-content-center">
                    <div className="col-md-10">
                      <textarea
                        className="form-control"
                        placeholder="Your Message"
                        onChange={(e) => setMessage(e.target.value)}
                        id="floatingTextarea"
                      ></textarea>
                    </div>
                  </div>
                  <div className="row mt-3  ">
                    <div className="col-md-4 offset-md-1">
                      <button type="submit" className="btn btn-dark">
                        Submit
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="col-md-6 my-auto form-pad">
            <div className="row mt-3 d-flex justify-content-center">
              <div className="col-md-1 my-auto d-none d-md-block">
                <i className="fas fa-map-marker-alt"></i>
              </div>
              <div className="col-md-6">
                <i className="fas fa-map-marker-alt d-xs-block d-md-none"></i>
                <span>
                  {" "}
                  Shri Ganapa Enterprises, 19/98/A Adkathimar, Ammeri P.O,
                  Paivalike, Kasargod, Kerala-671322
                </span>
              </div>
            </div>
            <div className="row mt-3 d-flex justify-content-center">
              <div className="col-md-1 my-auto d-none d-md-block">
                <i className="fas fa-phone-alt"></i>
              </div>
              <div className="col-md-6">
                <i className="fas fa-phone-alt d-xs-block d-md-none"></i>
                <span>+91 78920 10564</span>
              </div>
            </div>
            <div className="row mt-3 d-flex justify-content-center">
              <div className="col-md-1 my-auto d-none d-md-block">
                <i className="fas fa-envelope"></i>
              </div>
              <div className="col-md-6">
                <i className="fas fa-envelope d-xs-block d-md-none"></i>
                <span>contact.shriganapa@gmail.com</span>
              </div>
            </div>
            <div className="row mt-3 d-flex  justify-content-center">
              <a
                href={`${Endpoint.instagram}`}
                target="_blank"
                rel="noreferrer"
                className="row text-dark text-center p-0 m-0 d-block d-md-none border border-dark "
              >
                <i className="fab fa-instagram ">
                  <span className=" mx-3">Instagram</span>
                </i>
              </a>
              <a
                href={`${Endpoint.facebook}`}
                target="_blank"
                rel="noreferrer"
                className="row text-dark text-center p-0 m-0 mt-3 d-block d-md-none border border-dark "
              >
                <i className="fab fa-facebook ">
                  <span className="mx-3">Facebook</span>
                </i>
              </a>
            </div>
          </div>
          <div
            className="d-none d-md-block col-md-3  col-lg-2  mx-auto icons-move"
            style={icons}
          >
            <a target="_blank" rel="noreferrer" href={`${Endpoint.instagram}`}>
              <div
                className="row py-2 px-1 d-none d-sm-block "
                style={
                  onHover.state === true && onHover.type === 1
                    ? change
                    : nochange
                }
                onMouseOver={() => setOnHover({ state: true, type: 1 })}
                onMouseOut={() => setOnHover({ state: false, type: 1 })}
              >
                <i className="fab fa-instagram ">
                  <span className=" mx-3">Instagram</span>
                </i>
              </div>
            </a>
            <a target="_blank" rel="noreferrer" href={`${Endpoint.facebook}`}>
              <div
                className="row py-2 px-1 mt-3 d-none d-sm-block "
                style={
                  onHover.state === true && onHover.type === 2
                    ? change
                    : nochange
                }
                onMouseOver={() => setOnHover({ state: true, type: 2 })}
                onMouseOut={() => setOnHover({ state: false, type: 2 })}
              >
                <i className="fab fa-facebook ">
                  <span className="mx-3">Facebook</span>
                </i>
              </div>
            </a>
          </div>
        </form>
        <b>
          <h6
            className="row my-1 text-dark d-flex justify-content-center align-items-center"
            style={{ fontSize: "0.8rem" }}
          >
            &copy;Copyright 2021 Shri Gaapa Enterprises
          </h6>
        </b>
      </div>
    </div>
  );
}

export default Form;
